/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { CampfireEnterpriseIcon, CampfireEnterpriseCol, CampfireEnterpriseDropdown, CampfireEnterpriseContainer, CampfireEnterpriseGrid, CampfireEnterpriseTagGroup, CampfireEnterpriseTag, CampfireEnterpriseChatInputArea } from '@etrade-design-language/campfire-enterprise-react';
import Sidebar from './components/sidebar';
import ChatBubble from './components/chat-bubble';
import TasksLanding from './components/tasks-landing';
import ChatInput from './components/chat-input';
// import Preamble from './components/message-templates/preamble';
import { taskDropdownOptions, taskDropdownOptions2, last7Days, uploads } from './mock-data';
import { mockResponse } from './helpers-methods';
import './App.css';
import Summarize from './components/message-templates/summarize';
import Drafter from './components/message-templates/drafter';
import Debrief from './components/message-templates/debrief';
import Guru from './components/message-templates/guru';
import Muse from './components/message-templates/muse';

export default function AimsPlus() {
  const initMessages = [];
  const [messages, setMessages] = useState(initMessages);
  const [textInputVal, setTextInputVal] = useState('');
  const [isAwaitingAiResponse, setIsAwaitingAiResponse] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [historyLast7, setHistoryLast7] = useState(last7Days);
  const [uploadsHistory, setUploadsHistory] = useState(uploads);
  const [isTasksOpen, setIsTasksOpen] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [taskDropdownValue, setTaskDropdownValue] = useState('Basic');
  // const [files, setFiles] = useState(['cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg', 'cool.jpg']);
  const [files, setFiles] = useState([]);
  // const [files, setFiles] = useState(['cool.jpg']);
  const [showAsking, setShowAsking] = useState(false);
  const [enableFileUpload, setEnableFileUpload] = useState(true);
  // ability to a/b test content
  const [contentGroup, setContentGroup] = useState('a');
  const [correctP, setCorrectP] = useState(false);
  const [mockMessageTimeout, setMockMessageTimeout] = useState(0);
  const [isFileBeingUploaded, setIsFileBeingUploaded] = useState(false);
  const srOnlyRef = useRef(null);

  const clearMessages = () => {
    setMessages(prevMessages => []);
  }

  const newClick = () => {
    clearMessages();
    historyDrawerOpen(false);
    setIsMobileDrawerOpen(false);
    setTaskDropdownValue('Basic');
    setShowAsking(false);
    setEnableFileUpload(true);
  }

  const menuClick = () => {
    setIsMobileDrawerOpen(!isMobileDrawerOpen)
  }

  const historyDrawerOpen = (val) => {
    setIsTasksOpen(false);
    setIsHistoryOpen(val);
    setIsDrawerOpen(val);
  }

  const tasksDrawerOpen = (val) => {
    if (val && (window?.innerWidth <= '500')) return alert('All Workflows 📝')
    setIsHistoryOpen(false);
    setIsTasksOpen(val);
    setIsDrawerOpen(val);
  }

  const closeDrawers = () => {
    if (window?.innerWidth <= '500') {
      historyDrawerOpen(false);
    } else {
      setIsMobileDrawerOpen(false);
    }
  }

  const inputOnBlur = () => {
    if (!textInputVal && !(messages?.length) && !(files.length) && !isFileBeingUploaded) {
      setShowAsking(false);
    }
  }

  window.onresize = closeDrawers;

  const fileAttachHandler = (e) => {
    if (!e?.target?.files) return;
    const newFiles = Array.from(e?.target?.files).map((file) => file.name);
    setIsFileBeingUploaded(true);

    setTimeout(() => {
      setFiles([...files, ...newFiles]);
      setIsFileBeingUploaded(false);

      srOnlyRef.current.innerHTML = `file attached`;
      setTimeout(() => {
        srOnlyRef.current.innerHTML = "";
      }, 3000)
    }, 1000);
  }

  const tagDismiss = (tag) => {
    const tagIndex = parseInt(tag.target.dataset.testid);
    const tempTags = [...files];
    tempTags.splice(tagIndex, 1)
    setFiles(tempTags);
  }

  const addMessage = (messageObj) => {
    const time = new Date().toLocaleString().split(',')[1];
    const { content, files, isBot, author, feedbackFooter } = messageObj;

    const newMessage = {
      message: content,
      timeStamp: time,
      files,
      isBot,
      author,
      feedbackFooter,
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);

    // scoll to bottom after new message appears
    setTimeout(() => {
      const chatInner = document.getElementById('chat-inner');
      setTimeout(() => { chatInner.scrollTop = chatInner?.scrollHeight; }, 100);
    }, 100);
  }

  const askAi = (message) => {
    setIsAwaitingAiResponse(true);
    let lastMessage = messages.slice(-1)?.[0]?.message;
    if (!(typeof lastMessage === 'string')) { lastMessage = '' };
    // Here is where you might fetch to add responses to messages
    setMockMessageTimeout(setTimeout(() => {
      addMessage(mockResponse(lastMessage, addMessage, contentGroup));
      setIsAwaitingAiResponse(false);
    }, 3000));

    console.log('mock this message timeout', mockMessageTimeout);
  }

  const cancelResponse = () => {
    console.log('cancel response', mockMessageTimeout);
    setIsAwaitingAiResponse(false);
    clearTimeout(mockMessageTimeout);
  }

  useEffect(() => {
    console.log('messages 🔔', messages);
    const lastMessage = messages.slice(-1)?.[0];
    const secondToLastMessage = messages.slice(-2)?.[0];

    if (messages.length) {
      setShowAsking(true);
    }

    if (messages.length && !lastMessage?.isBot) {
      askAi(lastMessage?.message);
    } else if ((messages.length && !secondToLastMessage?.isBot)) {
      // FOR TESTING SCREENREADER BEHAVIOR OF TWO MESSAGE REPLIES
      // setTimeout(() => {
      //   addMessage(answers[Math.floor(Math.random() * answers.length)], true);
      // }, 1000);
    }
  }, [messages]);



  useEffect(() => {
    if (messages?.length || files?.length || isFileBeingUploaded) {
      setShowAsking(true);
    }
  }, [messages, files, isFileBeingUploaded]);

  function hex(str) {
    var hex = '';
    for (var i = 0; i < str.length; i++) {
      hex += '' + str.charCodeAt(i).toString(16);
    }
    return hex;
  }

  useEffect(() => {
    console.log('initial load 👋');
    if (window.location.href.includes('http://aims')) {
      console.log('redirecting 🏹');
      window.location.href = window.location.href.replace('http:', 'https:');
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const content = urlParams?.get('content');

    // ability to a/b test content
    if (content === 'b') {
      console.log('settingAlternateContent');
      setContentGroup(content);
    }

    const p = localStorage.getItem('p');

    if (p === '53757266706169723737') {
      setCorrectP(true);
    } else {
      let w = '';

      while (!w) {
        let entered = window.prompt('Please enter password:');
        let hexer = '';
        hexer = hex(entered);

        if (hexer === '53757266706169723737') {
          setCorrectP(true);
          localStorage.setItem('p', '53757266706169723737');
          w = '53757266706169723737';
        }
      }
    }
  }, []);

  const askBotClick = () => {
    if (!messages.length) {
      const tempHistoryLast7 = [textInputVal, ...historyLast7]
      setHistoryLast7(tempHistoryLast7);
    }

    if (files.length) {
      const tempUploadsHistory = [...files, ...uploadsHistory];
      setUploadsHistory(tempUploadsHistory);
    }

    addMessage({ content: textInputVal, isBot: false, files });
    setFiles([]);
    setTextInputVal('');
  }

  const changeHandler = (event) => {
    setTextInputVal(event.target.value);
  }

  const taskSelectClick = (task) => setTaskDropdownValue(task);

  const taskDropdownSelect = (event) => setTaskDropdownValue(event?.detail);

  const summarizeClick = () => {
    clearMessages();
    taskSelectClick('Summarizer');

    addMessage({ content: <Summarize addMessage={addMessage} />, isBot: true, author: (contentGroup === 'b') ? 'Summarize Content' : 'Summarizer' });
  }

  const drafterClick = () => {
    clearMessages();
    taskSelectClick('Email Wizard');

    addMessage({
      content: <Drafter addMessage={addMessage} />, isBot: true, author: (contentGroup === 'b') ? 'Write an email' : 'Email Wizard'
    });
  }

  const debriefClick = () => {
    clearMessages();
    taskSelectClick('Debrief');
    addMessage({
      content: <Debrief addMessage={addMessage} />, isBot: true, author: (contentGroup === 'b') ? 'Capture meeting notes' : 'Debrief'
    });
  }

  const guruClick = () => {
    clearMessages();
    taskSelectClick('Prompt Guru');

    addMessage({
      content: <Guru addMessage={addMessage} />, isBot: true, author: (contentGroup === 'b') ? 'Write a prompt' : 'Prompt Guru'
    });

  }

  const museClick = () => {
    clearMessages();
    taskSelectClick('Marketing Muse');
    addMessage({
      content: <Muse addMessage={addMessage} />, isBot: true, author: (contentGroup === 'b') ? 'Create marketing content' : 'Marketing Muse'
    });
  }

  return (
    <div >
      {correctP && <CampfireEnterpriseContainer background="#F5F5F5" className="aims-container">
        {/* Header */}
        <CampfireEnterpriseGrid className="aims-header">
          <CampfireEnterpriseCol
            flex="row"
            span="4"
            className="aims-heading-mobile-spacer">
            <button className="aims-menu-btn aims-btn-reset aims-white-focus-ring" aria-expanded={isMobileDrawerOpen} aria-label="menu" onClick={menuClick}>
              {!isMobileDrawerOpen && <img src="menu.svg" alt="" className="aims-menu-icon" />}
              {!!isMobileDrawerOpen && <CampfireEnterpriseIcon className="aims-menu-close-icon">
                close
              </CampfireEnterpriseIcon>}
            </button>
          </CampfireEnterpriseCol>
          <CampfireEnterpriseCol
            flex="row"
            span="20"
            className="aims-heading-logo">
            <span className="aims-ms-logo"></span>
            <h1 className="aims-main-heading">
              <button className="aims-btn-reset aims-white-focus-ring" onClick={clearMessages}>
                <CampfireEnterpriseIcon className="aims-ai-icon">
                  ai
                </CampfireEnterpriseIcon>
                <span className="aims-heading-text">AI@MS+</span>
              </button>
            </h1>
          </CampfireEnterpriseCol>

          <CampfireEnterpriseCol
            flex="row"
            span="16"
            className="aims-heading-logo-mobile">
            <h1 className="aims-main-heading">
              <button className="aims-btn-reset aims-white-focus-ring" onClick={clearMessages}>
                <CampfireEnterpriseIcon className="aims-ai-icon">
                  ai
                </CampfireEnterpriseIcon>
                <span className="aims-heading-text">AI@MS+</span>
              </button>
            </h1>
          </CampfireEnterpriseCol>

          <CampfireEnterpriseCol flex="row" span="4" className="aims-user-col">
            <button className="aims-user-btn aims-btn-reset aims-white-focus-ring" onClick={() => { alert('User profile 👩') }}>
              <div className="aims-user-icon">A</div>
              <div className="aims-user-name">Anna</div>
              <CampfireEnterpriseIcon className="aims-ai-icon aims-user-caret">
                chevron_down
              </CampfireEnterpriseIcon>
            </button>
            <button className="aims-header-new-btn aims-btn-reset aims-white-focus-ring" onClick={newClick}>
              <CampfireEnterpriseIcon className="">
                add_item
              </CampfireEnterpriseIcon>
            </button>
          </CampfireEnterpriseCol>
        </CampfireEnterpriseGrid>

        {/* Wrapper */}
        <div className={`aims-wrap ${isDrawerOpen ? 'aims-wrap--drawer-open' : ''} ${isMobileDrawerOpen ? 'aims-wrap--mobile-drawer-open' : ''}`}>

          <Sidebar
            newClick={newClick}
            isHistoryOpen={isHistoryOpen}
            isDrawerOpen={isDrawerOpen}
            isTasksOpen={isTasksOpen}
            contentGroup={contentGroup}
            historyDrawerOpen={historyDrawerOpen}
            tasksDrawerOpen={tasksDrawerOpen}
            historyLast7={historyLast7}
            uploadsHistory={uploadsHistory}
            debriefClick={debriefClick}
            summarizeClick={summarizeClick}
            museClick={museClick}
            guruClick={guruClick}
            drafterClick={drafterClick} />

          {/* Main */}
          <div className="aims-main-wrap">
            <div className={`aims-main ${messages?.length ? "" : "aims-main--initial"}`} >
              <div className="aims-chat-and-input-wrap">
                <div className={`aims-chat-outer ${messages?.length ? "" : "aims-chat-outer--initial"}`}>

                  {/* Select task landing */}
                  {!messages?.length && <TasksLanding
                    taskSelectClick={taskSelectClick}
                    tasksDrawerOpen={tasksDrawerOpen}
                    summarizeClick={summarizeClick}
                    debriefClick={debriefClick}
                    drafterClick={drafterClick}
                    guruClick={guruClick}
                    museClick={museClick}
                    contentGroup={contentGroup}
                    isTasksOpen={isTasksOpen}
                    addMessage={addMessage} />}

                  {/* Messages */}
                  {!!messages?.length && <ul id="chat-inner" className="aims-chat-inner" tabIndex="0" aria-label="chat conversation" aria-live="polite">
                    <div role="presentation">
                      <CampfireEnterpriseGrid >
                        <CampfireEnterpriseCol span="24">
                          <p className="aims-chat-legal">
                            Results may now be generated by Al. Results are for Internal Use Only. It is your responsibility to verify the accuracy and relevancy of information via the source material. The virtual assistant tool is not a substitute for any topic or issue that requires escalation to Legal and Compliance (e.g. policy or regulatory matters). Before using this tool to search for information, click here to learn about usage terms. Please do not include confidential information such as MNPI, PIl, passwords, PINS, or social security numbers in the chat. Definitions can be found here.
                          </p>
                        </CampfireEnterpriseCol>

                        <CampfireEnterpriseCol span="24">
                          {messages.map((m, index) => (<ChatBubble
                            key={index}
                            message={m.message}
                            timeStamp={m.timeStamp}
                            files={m.files}
                            author={m.author}
                            isBot={m.isBot}
                            feedbackFooter={m.feedbackFooter}
                          >
                          </ChatBubble>))}
                        </CampfireEnterpriseCol>
                      </CampfireEnterpriseGrid>
                    </div>
                    <div>
                      {(!!isAwaitingAiResponse) && (
                        <div className="loader"></div>
                      )}
                    </div>
                  </ul>}

                </div>
                <div className="aims-input-outer">
                  <CampfireEnterpriseGrid verticalOffset="clear" >
                    <CampfireEnterpriseCol span="24">
                      <CampfireEnterpriseChatInputArea
                        placeholder="Type a message"
                        verticalOffset="clear"
                        onChatInput={changeHandler}
                        onChatSend={askBotClick}
                        value={textInputVal}
                        testId="aims-chat-input-area"
                        className="campy-chat-area"
                      >
                        <>
                          {/* <CampfireEnterpriseFileUpload
                            verticalOffset="clear"
                            slot="actions"
                            type="simple-compact"
                            onFileChange={fileAttachHandler}
                          /> */}

                          <label slot="actions" className="aims-file-upload-label aims-btn-reset" tabIndex="0" >
                            <span className="aims-sr-only">Attach file</span>
                            <input className="aims-file-input" type="file" onChange={fileAttachHandler} />
                            {/* <img src="paperclip.svg" alt="" /> */}
                            <CampfireEnterpriseIcon className="aims-upload-icon">
                              upload
                            </CampfireEnterpriseIcon>
                          </label>

                          <CampfireEnterpriseDropdown
                            label="Workflow:"
                            className="aims-task-select"
                            inline
                            slot="actions"
                            options={contentGroup === 'b' ? taskDropdownOptions2 : taskDropdownOptions}
                            onDropdownSelect={taskDropdownSelect}
                            value={taskDropdownValue}
                            promptText="Select"
                            size="body"
                            verticalOffset="clear"
                          />

                          <CampfireEnterpriseTagGroup slot="files">
                            {files.map((file, index) => (<CampfireEnterpriseTag controlled dismissible status="informational" key={index} testId={index} onTagDismiss={tagDismiss}>{file}</CampfireEnterpriseTag>))}
                          </CampfireEnterpriseTagGroup>
                        </>
                      </CampfireEnterpriseChatInputArea>

                      <ChatInput
                        onChatInput={changeHandler}
                        onChatSend={askBotClick}
                        value={textInputVal}
                        showAsking={showAsking}
                        setShowAsking={setShowAsking}
                        isAwaitingAiResponse={isAwaitingAiResponse}
                        contentGroup={contentGroup}
                        taskDropdownSelect={taskDropdownSelect}
                        taskDropdownValue={taskDropdownValue}
                        fileAttachHandler={fileAttachHandler}
                        files={files}
                        tagDismiss={tagDismiss}
                        cancelResponse={cancelResponse}
                        isFileBeingUploaded={isFileBeingUploaded}
                        inputOnBlur={inputOnBlur}
                        enableFileUpload={enableFileUpload}
                      />
                    </CampfireEnterpriseCol>
                  </CampfireEnterpriseGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="aims-sr-only" ref={srOnlyRef} aria-live="polite"></div>
      </CampfireEnterpriseContainer >}
    </div >
  )
}