import React, { useState, useRef, useEffect } from 'react';
import { CampfireEnterpriseSpinner, CampfireEnterpriseDropdown, CampfireEnterpriseTagGroup, CampfireEnterpriseTag, CampfireEnterpriseMessageBanner, CampfireEnterpriseFileUpload, CampfireEnterpriseButton } from '@etrade-design-language/campfire-enterprise-react';
import '../style/chat-input.css'
import { taskDropdownOptions, taskDropdownOptions2 } from '../mock-data';

export default function ChatInput({ onChatInput, onChatSend, value, contentGroup, taskDropdownSelect, taskDropdownValue, showAsking, setShowAsking, isAwaitingAiResponse, fileAttachHandler, files, tagDismiss, cancelResponse, isFileBeingUploaded, inputOnBlur, enableFileUpload }) {
  const [showNoTextError, setShowNoTextError] = useState(false);
  const [isTextAreaMultiLine, setIsTextAreaMultiLine] = useState(false);
  const textAreaRef = useRef(null);

  // Splitting out first option grouping in dropdown
  const gptOptionsGroupedForDropdown = contentGroup === 'b' ? [[taskDropdownOptions2[0]], taskDropdownOptions2.slice(1)] : [[taskDropdownOptions[0]], taskDropdownOptions.slice(1)];

  const send = () => {
    onChatSend();
  }

  const textInputOnFocus = () => {
    setShowAsking(true);
  }

  const submitClick = () => {
    if (!isAwaitingAiResponse) {
      if (value) {
        onChatSend();
      } else {
        setShowNoTextError(true);
      }

    } else {
      cancelResponse();
    }
  }

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the message input container
      if (!currentTarget.contains(document.activeElement)) {
        inputOnBlur()
      }
    });
  };

  const onTextAreaKeyUp = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      // Stops enter from creating a new line 
      if (!isAwaitingAiResponse) {
        event.preventDefault();
        return send()
      };
    }
  }

  useEffect(() => {
    textAreaRef.current.style.height = '17px';
    if (textAreaRef.current.scrollHeight <= 28 && !(files?.length) && !isFileBeingUploaded) {
      textAreaRef.current.style.height = '17px';
      if (!value) setIsTextAreaMultiLine(false);
    } else {
      if (textAreaRef.current.scrollHeight > 5) {
        console.log('cool inside 15', textAreaRef.current.scrollHeight);
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
      setIsTextAreaMultiLine(true);
    }
    if (value) {
      setShowNoTextError(false);
    }
  }, [value, files, isFileBeingUploaded]);

  return (
    <div className='chat-input-area' onBlur={handleBlur}>
      <div className="ms-error-wrapper" aria-live="polite">
        {showNoTextError && <CampfireEnterpriseMessageBanner
          message="Please enter a message."
          type="critical"
          verticalOffset="base"
        />}
      </div>
      <div className="input-box">
        {!!showAsking && <div className="aims-asking-wrapper">
          <CampfireEnterpriseDropdown
            label="Asking"
            className="aims-chat-input-task-select"
            inline
            slot="actions"
            options={gptOptionsGroupedForDropdown}
            onDropdownSelect={taskDropdownSelect}
            value={taskDropdownValue}
            promptText="Select"
            size="body"
            verticalOffset="clear"
          />
          <div className="aims-asking-message">Tip: Hit Shift + Enter Key for next line.</div>
        </div>
        }
        <div className={`input-area ${!isTextAreaMultiLine && 'input-area--short'}`}>
          <textarea onKeyDown={onTextAreaKeyUp} onInput={onChatInput} onFocus={textInputOnFocus} value={value} className="aims-textarea" placeholder="Type a message" rows="1" ref={textAreaRef}></textarea>
        </div>

        <div className={`aims-actions-wrap ${isTextAreaMultiLine && 'aims-actions-wrap-multiline-textarea'}`}>
          <div className="aims-tag-wrap">
            {!!files?.length &&
              <CampfireEnterpriseTagGroup>
                {files.map((file, index) => (<CampfireEnterpriseTag controlled dismissible status="informational" key={index} testId={index} onTagDismiss={tagDismiss}>{file}</CampfireEnterpriseTag>))}
              </CampfireEnterpriseTagGroup>
            }
            {!!isFileBeingUploaded && <div className="aims-file-loader-wrap">
              <CampfireEnterpriseSpinner
                percentage="Loading..."
                show={true}
                size="small"
              />
            </div>
            }
          </div>
          <div className="actions-box">
            <div className="actions-box-inner">
              {!!enableFileUpload &&
                <CampfireEnterpriseFileUpload
                  type="simple-compact"
                  verticalOffset="clear"
                  multiple={true}
                  icon="attach"
                  onFileChange={fileAttachHandler}
                />
              }
              {!!enableFileUpload &&
                <div className="aims-actions-box-divider"></div>
              }
              <CampfireEnterpriseButton type="inline-link" onButtonClick={submitClick}>
                <span aria-live="polite">
                  {!isAwaitingAiResponse && <img src="send.svg" alt="submit" />}
                  {!!isAwaitingAiResponse && <img src="stop.svg" alt="stop" />}
                </span>
              </CampfireEnterpriseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}